<template>
  <van-swipe
    v-if="images && images.length != 0"
    class="my-swipe bm_Swiper"
    :autoplay="3000"
    indicator-color="white"
  >
    <van-swipe-item v-for="(image, index) in images" :key="index">
      <img v-lazy="image.image_url" @click="goHref(image.location_url)" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import { get } from "@get/http.js";
export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    goHref(src) {
      window.location.href = src;
    },
  },
  async created() {
    let data = await get("/GoodsArticle/getImage?type=1");
    this.images = data?.image_list || [];
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.bm_Swiper {
  // 轮播图
  width: rem(690);
  height: rem(280);
  margin: 0 auto rem(20);
  border-radius: rem(16);
  img {
    border-radius: rem(16);
    display: block;
    width: 100%;
    height: 100%;
  }
}

// .van-swipe__track ::v-deep {
//   border-radius: rem(16);
// }

// .van-swipe-item ::v-deep {
//   border-radius: rem(16);
// }
</style>
