<template>
  <div>
    <van-action-sheet v-model="show">
      <div class="content">
        <h1>工具</h1>

        <div class="bbsMall_Tool-ul flex-container" v-if="isWechat">
          <div class="bbsMall_Tool-li flex-container">
            <wx-open-launch-weapp
              class="launch"
              username="gh_ee6bf25c5178"
              path="/pages/index/index.html"
            >
              <script type="text/wxtag-template">
                <img src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021041018302183855.png" style="width: 100%;height:100%; display:block;vertical-align: top;" />
              </script>
            </wx-open-launch-weapp>
            <div class="xcx flex1">
              <h2>咔咔妆</h2>
              <p>眉型设计</p>
            </div>
            <img src="@image/bbsMall/kk_icon/kkz_icon.png" />
          </div>
          <div class="bbsMall_Tool-li flex-container">
            <wx-open-launch-weapp
              class="launch"
              username="gh_64229885f4d8"
              path="/pages/index/index.html"
            >
              <script type="text/wxtag-template">
                <img src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021041018302183855.png" style="width: 100%;height:100%; display:block;vertical-align: top;" />
                  <!-- <div ><div> -->
              </script>
            </wx-open-launch-weapp>
            <div class="xcx flex1">
              <h2>纹绣案例助手</h2>
              <p>作品管理</p>
            </div>
            <img src="@image/bbsMall/kk_icon/anli_icon.png" />
          </div>
        </div>

        <div class="bbsMall_Tool-ul flex-container" v-else>
          <div class="bbsMall_Tool-li flex-container" @click="aler()">
            <div class="xcx flex1">
              <h2>咔咔妆</h2>
              <p>眉型设计</p>
            </div>
            <img src="@image/bbsMall/kk_icon/kkz_icon.png" />
          </div>
          <div class="bbsMall_Tool-li flex-container" @click="aler()">
            <div class="xcx flex1">
              <h2>纹绣案例助手</h2>
              <p>作品管理</p>
            </div>
            <img src="@image/bbsMall/kk_icon/anli_icon.png" />
          </div>
        </div>

        <img
          src="@image/bbsMall/kk_icon/botton_icon.png"
          class="hide_button"
          @click="show = false"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { ActionSheet } from "vant";
export default {
  components: {
    vanActionSheet: ActionSheet,
  },
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.isWeiXin();
    this.show = this.boo;
  },
  watch: {
    show() {
      this.$emit("update:boo", this.show);
    },
    boo() {
      this.show = this.boo;
    },
  },
  methods: {
    aler() {
      alert("只能微信打开");
    },
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWechat = true;
      } else {
        this.isWechat = false;
      }
    },
  },
  data() {
    return {
      isWechat: true,
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep .van-popup {
  background: #f8f8f8;
}
h1 {
  font-size: rem(34);
  font-weight: 700;
  color: #333333;
  margin-top: rem(50);
  padding: 0 rem(30);
}
.content {
  height: rem(563);
  position: relative;
}
.bbsMall_Tool-ul {
  box-sizing: border-box;
  padding: 0 rem(30);
  justify-content: space-between;
  margin-top: rem(40);
}

.bbsMall_Tool-li {
  width: rem(335);
  height: rem(188);
  background: #ffffff;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding: 0 rem(30);
  > img {
    width: rem(64);
    height: rem(64);
    background: #ffffff;
    // border: 1px solid #e6e6e6;
    border-radius: 50%;
  }
  .xcx {
    h2 {
      font-size: rem(30);
      font-weight: 500;
      color: #333333;
      margin-bottom: rem(10);
    }
    p {
      font-size: rem(24);
      font-weight: 500;
      color: #999999;
    }
  }
}
.hide_button {
  width: rem(30);
  height: rem(15);
  position: absolute;
  bottom: rem(30);
  right: 0;
  left: 0;
  margin: 0 auto;
}
.launch {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9999;
}
</style>
