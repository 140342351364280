<template>
  <div class="bm_Index">
    <div class="bm-i-top">
      <!-- <div class="bm_Search flex-container">
        <img src="@/assets/image/bbsMall/index_search.png" />
        <input type="text" placeholder="请搜索 " class="flex1" />
      </div> -->
      <oSwiper></oSwiper>
    </div>
    <oKk></oKk>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <oInfoList
        :list="formData"
        title="为你推荐"
        :finished="finished"
      ></oInfoList>
    </van-list>
    <oUserTip v-if="bbsMallShowTip"></oUserTip>
  </div>
</template>

<script>
import { List } from "vant";
import oInfoList from "../components/infoList/";
import oKk from "./components/KK";
import oUserTip from "./components/oneUserTip.vue";
import oSwiper from "./components/swiper";
import { get } from "@get/http.js";
export default {
  components: {
    oInfoList,
    oUserTip,
    oKk,

    oSwiper,
    vanList: List,
  },
  data() {
    return {
      pageSize: 20,
      page: 1,
      loading: false,
      finished: false,

      bbsMallShowTip: false,
      formData: [],
    };
  },
  methods: {
    async onLoad() {
      let data = await get("/GoodsArticle/index", {
        params: {
          pageSize: this.pageSize,
          page: this.page,
        },
      });
      this.formData.push(...data.data);
      // 加载状态结束
      this.loading = false;
      this.page++;
      if (data.data.length != this.pageSize) {
        this.finished = true;
      }
    },
  },
  created() {
    //是否显示引导
    let l = localStorage["bbsMall_show_shop"];
    if (!l) {
      localStorage["bbsMall_show_shop"] = "1";
      this.bbsMallShowTip = true;
    }
  },
};
</script>
<style lang="scss">
.van-list__finished-text {
  display: none;
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.bm-i-top {
  padding-top: rem(30);
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
}
.bm_Index {
  // main
  overflow-x: hidden;
  width: rem(750);
  margin: 0 auto;
  display: block;
  background: #f8f8f8;
  min-height: 100%;
  max-height: 100%;
  height: rem(1334);
  position: relative;
  padding-bottom: rem(30);
}

$search_height: 72;
.bm_Search {
  // 搜索
  align-items: center;
  background: #f7f7f7;
  border-radius: rem($search_height/2);
  width: rem(690);
  margin: 0 auto rem(30);

  height: rem($search_height);
  img {
    width: rem(36);
    height: rem(36);
    display: block;
    margin: 0 rem(16) 0 rem(32);
  }
  input {
    border: none;
    background: #f7f7f7;
    color: #333;
    font-size: rem(30);
    margin-right: rem(32);
    line-height: rem(42);
    &::-webkit-input-placeholder {
      /* Edge */
      color: #ccc;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccc;
    }
    &::placeholder {
      color: #ccc;
    }
  }
}
</style>
