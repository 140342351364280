<template>
  <div class="bm_oneTip" @click="cancalWrap" v-if="wrap">
    <div class="imgTip">
      <img src="@image/bbsMall/yindao_tip_wrap.png" />
      <div @click="cancalWrap">知道了</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wrap: true,
    };
  },
  methods: {
    cancalWrap() {
      this.wrap = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.bm_oneTip {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0%;
  z-index: 3;
  .imgTip {
    position: relative;
    img {
      padding-top: 18.8%;
      padding-left: 1%;
      width: 100%;
    }
    div {
      position: absolute;
      width: rem(150);
      height: rem(72);
      bottom: rem(40);
      right: 30%;
      background: linear-gradient(89.16deg, #ff7f48 0.72%, #ff4848 99.37%);
      border-radius: rem(36);
      font-size: rem(28);
      line-height: rem(72);
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
