<template>
  <div class="bm_icon flex-container">
    <div
      class="bm-icon_list flex1"
      @click="$router.push({ name: 'HomeIndex' })"
    >
      <img src="@image/bbsMall/kk_icon/shop_icon-new.png" />
    </div>

    <div class="bm-icon_list flex1" v-if="!isWechat" @click="aler">
      <img src="@image/bbsMall/kk_icon/bbs_icon-new.png" />
    </div>

    <div class="bm-icon_list flex1" v-if="isWechat">
      <wx-open-launch-weapp
        id="launch"
        username="gh_4a77eb1f49c1"
        path="/pages/index/index.html"
      >
        <script type="text/wxtag-template">
          <img src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021041018272677634.png" style="width: 100%;height:100%; display:block;vertical-align: top;" />
        </script>
      </wx-open-launch-weapp>
      <img src="@image/bbsMall/kk_icon/bbs_icon-new.png" />
    </div>

    <div
      class="bm-icon_list flex1"
      @click="
        $router.push({ name: 'HomeGoodsdetail', query: { goods_id: 3526 } })
      "
    >
      <img src="@image/bbsMall/kk_icon/vip_icon-new.png" />
    </div>
    <div class="bm-icon_list flex1" @click="showToolBoo = true">
      <img src="@image/bbsMall/kk_icon/tool_icon-new.png" />
    </div>
    <oTool :boo.sync="showToolBoo"></oTool>
  </div>
</template>

<script>
import oTool from "../Tool";
export default {
  components: {
    oTool,
  },
  data() {
    return {
      showToolBoo: false,
      isWechat: true,
    };
  },
  methods: {
    aler() {
      alert("仅限微信打开");
    },
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWechat = true;
      } else {
        this.isWechat = false;
      }
    },
  },
  created() {
    this.isWeiXin();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
$icon_height: 116;
$icon_width: 92;
.bm_icon {
  // icon
  #launch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  margin-bottom: rem(20);
  padding-top: rem(20);
  .bm-icon_list {
    position: relative;

    img {
      display: block;
      margin: 0 auto rem(20);
    }
    b {
      display: block;
      text-align: center;
      font-size: rem(26);
      line-height: rem(36);
      color: #666666;
    }
    &:nth-of-type(1) {
      img {
        width: rem($icon_width);
        height: rem($icon_height);
      }
    }

    &:nth-of-type(2) {
      img {
        width: rem($icon_width);
        height: rem($icon_height);
      }
    }

    &:nth-of-type(3) {
      img {
        width: rem($icon_width);
        height: rem($icon_height);
      }
    }
    &:nth-of-type(4) {
      img {
        width: rem($icon_width);
        height: rem($icon_height);
      }
    }
  }
}
</style>
